const windowAnalytics = window
const ym = windowAnalytics.ym || (() => {})
const YM_COUNTER_ID = 95827082
const isProduction = process.env.NODE_ENV === 'production'

export const analytics = {
  send(event, params) {
    console.log('----ANALYTICS---', event, params)
    if (isProduction) {
      ym(YM_COUNTER_ID, 'reachGoal', event, params)
    }
  },
  setUser(id) {
    if (isProduction) {
      console.log('----ANALYTICS---', 'setUserID', id)
      ym(YM_COUNTER_ID, 'setUserID', id)
    }
  }
}
