<template>
  <div class="purchase transfer__wrapper">
    <form-layout
      :small-title="true"
      :title="$t('superPass.purchase.title')"
      :sub-title="$t('superPass.purchase.subTitle')"
      :back="goBack"
    >
      <div class="transfer__form">
        <template v-if="balanceError">
          <div class="transfer__form-content__error__body">
            <img src="@/assets/images/transfer/wait.svg" alt="deny" />
            <div class="transfer__form-content__error gift">
              <span>{{ balanceError }}</span>
            </div>
            <button class="wait-btn" @click="$router.push('/payment')">
              {{ $t('navigation.dropdown.refill') }}
            </button>
          </div>
        </template>
        <Loader v-else-if="isLoadedTransfer" />
        <template v-else>
          <div class="transfer__form-cards">
            <PurchasePassCard title="Выбранная игра:" :entity="game" @edit="changeModal('SuperPassGames')" />
            <PurchasePassCard title="Выбранный пасс:" :entity="pass" @edit="changeModal('SuperPassPasses')" />

            <div class="transfer__form-payment">
              <Input
                :value="pass.amount[$store.state.currency] + ` ${currencyMap[$store.state.currency]}`"
                :disabled="true"
                icon="/transfer/rub.svg"
                :placeholder="$t('transfer.nickname')"
              />
              <Input
                :value="pass.robux_amount + ' R$'"
                :disabled="true"
                icon="/transfer/coin1.svg"
                :placeholder="$t('transfer.nickname')"
              />
            </div>
          </div>

          <div class="transfer__form-footer">
            <div v-if="error" class="transfer__form-footer-error">{{ error }}</div>
            <Button class="transfer__form-btn" @click="submit">
              {{ $t('payment.pay') }}
            </Button>
            <p class="payment__main__result">
              {{ $t('payment.toPay') }}:
              <span>
                {{ pass.amount[$store.state.currency] }}
                {{ currencyMap[$store.state.currency] }}
              </span>
            </p>
          </div>
        </template>
      </div>
    </form-layout>
  </div>
</template>

<script>
import './superPassPurchase.scss'

// import Loader from '@/components/LoaderBar/LoaderBar'
import Button from '@/components/Base/Button/Button.vue'
import FormLayout from '@/components/Base/FormLayout/FormLayout.vue'
import PurchasePassCard from '@/components/PurchasePassCard/PurchasePassCard.vue'
import Input from '@/components/Base/Input/Input.vue'
import Loader from '@/components/LoaderBar/LoaderBar.vue'

export default {
  name: 'SuperPassPurchase',
  components: {
    Loader,
    Input,
    PurchasePassCard,
    Button,
    FormLayout
    // Loader
  },
  inject: ['currencyMap'],

  props: {
    data: {
      type: Object,
      default: () => {}
    },
    game: {
      type: Object,
      default: () => {}
    },
    pass: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isLoadedTransfer: true,
      error: '',
      balanceError: ''
    }
  },
  methods: {
    goBack() {
      this.$emit('changeModal', {
        modal: 'TransferCharacters',
        data: []
      })
    },
    changeModal(modal) {
      this.$emit('changeModal', {
        modal
      })
    },
    submit() {
      this.error = ''
      this.balanceError = ''

      if (this.pass.amount[this.$store.state.currency] > this.$store.getters.balance) {
        this.$analytics.send('purch_super_e1_payment_insufficient', {})

        this.balanceError = this.$t('gift.balanceError')
        return
      }

      const requestHeaders = new Headers({
        Authorization: `Bearer ${this.$cookies.get('access_token')}`
      })

      const requestParams = {
        method: 'GET',
        headers: requestHeaders
      }

      const params = new URLSearchParams({
        superpass_id: this.pass._id,
        username: this.data?.nickname,
        currency: this.$store.state.currency?.toUpperCase()
      })

      fetch('https://transfer.backend-pier.com/make_superpass_order?' + params.toString(), requestParams)
        .then(res => res.json())
        .then(data => {
          if (!data.status) {
            this.error = this.$t('general.errors.server')
            return
          }

          this.$analytics.send('purch_super_4_payment_balance', {})

          this.$router.push({ name: 'History', query: { method: 'superPass' } })
        })
    },
    async checkActivePassOrder() {
      const requestHeaders = new Headers({
        Authorization: `Bearer ${this.$cookies.get('access_token')}`
      })

      const requestParams = {
        method: 'GET',
        headers: requestHeaders
      }

      try {
        const response = await fetch(
          'https://transfer.backend-pier.com/get_superpasses_history?count=7&page=1',
          requestParams
        )
        const data = await response.json()

        if (data.status) {
          const passOrders = data.orders

          if (passOrders?.length) {
            const isActiveOrder = ['paid', 'in_game'].includes(passOrders[0].status)

            if (isActiveOrder) {
              await this.$router.push({
                name: 'History',
                query: { method: 'superPass' }
              })
            }
          }
        } else {
          this.isLoadedTransfer = false
        }
      } catch (error) {
        console.error('Error fetching active pass order:', error)
        this.isLoadedTransfer = false
      } finally {
        this.isLoadedTransfer = false
      }
    }
  },
  created() {
    this.checkActivePassOrder()
  }
}
</script>
