<template>
  <div class="games transfer__wrapper">
    <form-layout :small-title="true" :title="$t('superPass.games.title')" :back="goToTransferMain">
      <template #sub-title>
        <div class="transfer__form__subtitle">
          {{ $t('superPass.games.subTitle') }}
          <a href="#" @click.prevent="openChat">
            {{ $t('superPass.games.support') }}
          </a>
        </div>
      </template>
      <div class="transfer__form">
        <Input v-model="search" :placeholder="$t('superPass.games.placeholder')" icon="/base/search.svg" type="text" />
        <div class="transfer__form-games-list">
          <Loader v-if="isLoadedTransfer" />
          <template v-else-if="gamesList.length">
            <div v-for="game in gamesList" :key="game._id" @click="selectGame(game)">
              <GameCard :game="game" :current="currentGame" />
            </div>
          </template>
          <EmptyListInfo v-else>
            <template #text>
              {{ $t('superPass.empty.game') }}
            </template>
            <template #footer>
              <span class="link" @click="openChat">{{ $t('superPass.empty.gameLink') }}</span>
            </template>
          </EmptyListInfo>
        </div>
        <Input class="transfer__form-search-input" v-if="isShowSearch" type="text" v-model="search" :light="true" />
        <div class="transfer__form-footer">
          <Button class="transfer__form-btn" :disabled="!currentGame.name" @click="changeModal">
            {{ $t('actions.continue') }}
          </Button>
        </div>
      </div>
    </form-layout>
  </div>
</template>

<script>
import './superPassGames.scss'

import Button from '@/components/Base/Button/Button.vue'
import FormLayout from '@/components/Base/FormLayout/FormLayout.vue'
import GameCard from '@/components/GameCard/GameCard.vue'

import { openChat } from '@/utils/chat'
import { isWithinWorkingHoursPass } from '@/utils/superPassWorkingHours'

import Input from '@/components/Base/Input/Input.vue'
import EmptyListInfo from '@/components/EmptyListInfo/EmptyListInfo.vue'
import Loader from '@/components/LoaderBar/LoaderBar.vue'

export default {
  name: 'SuperPassGames',
  components: {
    Loader,
    EmptyListInfo,
    Input,
    GameCard,
    Button,
    FormLayout
  },
  data() {
    return {
      isLoadedTransfer: false,
      games: [],
      currentGame: {},
      isShowSearch: false,
      search: ''
    }
  },
  mounted() {
    const isTimeValid = this.isWithinWorkingHoursPass()

    if (!isTimeValid) {
      this.$emit('changeModal', {
        modal: 'SuperPassTimeInfo',
        data: []
      })
      return
    }

    this.getGames()
  },
  computed: {
    gamesList() {
      if (this.search) {
        return this.games.filter(game => game.name.toLowerCase().includes(this.search.toLowerCase()))
      } else {
        return this.games
      }
    }
  },
  methods: {
    openChat,
    isWithinWorkingHoursPass,
    async changeModal() {
      this.$analytics.send('purch_super_1_game', {})

      this.$emit('changeModal', {
        modal: 'SuperPassPasses',
        game: this.currentGame
      })
    },
    goToTransferMain() {
      this.$emit('changeModal', {
        modal: 'MainForm',
        data: [this.currentGame]
      })
    },
    selectGame(game) {
      this.currentGame = game
    },
    getGames() {
      this.isLoadedTransfer = true
      fetch('https://transfer.backend-pier.com/superpasses')
        .then(res => res.json())
        .then(data => {
          if (data.games) {
            this.games = data.games.filter(game => game.enabled).sort((a, b) => b.online - a.online)
            this.games = this.games.map(game => {
              return {
                ...game,
                passes: game.passes.filter(pass => pass.enabled)
              }
            })
          }
        })
        .catch(err => console.log(err))
        .finally(() => {
          this.isLoadedTransfer = false
        })
    }
  }
}
</script>
