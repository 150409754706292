<template>
  <div v-if="pass" class="pass-card" :class="{ active }">
    <img :src="pass.image_uri" class="pass-card__cover" alt="cover" />
    <div class="pass-card__body">
      <div class="pass-card__name">{{ pass.name }}</div>
      <div class="pass-card__info">
        <div class="pass-card__price">
          {{ pass.amount[$store.state.currency] }}
          {{ currencyMap[$store.state.currency] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './passCard.scss'

export default {
  name: 'PassCard',
  inject: ['currencyMap'],

  props: {
    pass: {
      type: Object,
      default: () => {}
    },
    current: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    active() {
      return this.pass._id === this.current._id
    }
  }
}
</script>
