<template>
  <div v-if="entity" class="purchase-pass-card">
    <img :src="entity.image_uri" class="purchase-pass-card__cover" alt="cover" />
    <div class="purchase-pass-card__body">
      <div class="purchase-pass-card__info">
        <div class="purchase-pass-card__title">{{ title }}</div>
        <div class="purchase-pass-card__name">{{ entity.name }}</div>
      </div>

      <Icon class="purchase-pass-card__edit" name="/ForgetPassword/pen.svg" @click="$emit('edit')" />
    </div>
  </div>
</template>

<script>
import './purchasePassCard.scss'
import Icon from '@/components/Base/Icon/Icon.vue'

export default {
  name: 'PurchasePassCard',
  components: { Icon },
  props: {
    entity: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    }
  }
}
</script>
