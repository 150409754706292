<template>
  <div class="main__form transfer__wrapper">
    <form-layout :small-title="true" :title="title" :sub-title="method === METHODS.TRANSFER && $t('transfer.subTitle')">
      <template #header>
        <MethodSwitch
          :methods="methodsList"
          :current="method"
          @update="onMethodChange"
          @showTimeInfo="
            $emit('changeModal', {
              modal: 'SuperPassTimeInfo',
              data: []
            })
          "
        />
      </template>
      <template #sub-title>
        <div v-if="method === METHODS.GIFT" class="transfer__form-badge">
          {{ $t('gift.badge') }}
        </div>
      </template>
      <div class="transfer__form">
        <Loader v-if="loading.length > 0" />
        <template v-if="method === METHODS.TRANSFER">
          <div class="transfer__form-count">
            <p>{{ $t('transfer.available') }}</p>
            <small>{{ rbxAmount.toLocaleString('ru-RU') }} R$</small>
          </div>
          <div class="transfer__form-content">
            <div class="select">
              <p @click="selectItem(20, $event)" class="select__item">20 R$</p>
              <p @click="selectItem(60, $event)" class="select__item">60 R$</p>
              <p @click="selectItem(120, $event)" class="select__item">
                120 R$
              </p>
              <p @click="selectItem(300, $event)" class="select__item">
                300 R$
              </p>
            </div>
            <Input
              v-model="totalRobux"
              icon-text="R$"
              type="number"
              :hint="$t('transfer.minumum', { min: minToBuy })"
              :error="error.message"
              :state="error.show && error.status === false ? 'error' : 'default'"
              :placeholder="$t('transfer.robuxCount')"
              @input="validatePrice"
            />
            <promo-code-input
              class="transfer__form-promo"
              :class="{ active: isEnabledPromocode }"
              v-model="promoCode.value"
              :disabled="!totalRobux || promoCode.loading || !isEnabledPromocode"
              :status="promoCode.status"
              :error="promoCode.error"
              @apply="applyPromo(promoCode.value, totalRobux)"
            />
            <div class="transfer__form-promo-wrapper">
              <div v-if="!isEnabledPromocode" class="transfer__form-promo-switch" @click="isEnabledPromocode = true">
                {{ $t('transfer.promoSwitch') }}
              </div>
              <div v-if="!isEnabledPromocode" class="transfer__form-promo-info" @click="isShowPromocodeModal = true">
                {{ $t('transfer.promoInfo') }}
              </div>
            </div>
          </div>
          <Button class="transfer__form-btn" @click="changeModal">
            {{ $t('actions.continue') }}
          </Button>
          <div class="transfer__form-footer">
            <div class="count">
              <p>
                {{ $t('payment.total') }}:
                <span class="blue">{{ totalRobuxText }} R$</span>
              </p>
              <p v-if="promoCode.bonus">
                <span class="green">+{{ promoCode.bonus }} R$</span>
              </p>
            </div>
            <div class="total">
              <p>
                {{ $t('payment.toPay') }}:
                <span class="blue">
                  {{ getMoneyTotal().toFixed(2) }}
                  {{ currencyMap[$store.state.currency] }}
                </span>
              </p>
            </div>
          </div>
        </template>
        <template v-if="method === METHODS.GIFT">
          <template v-if="errorGift.show">
            <div class="transfer__form-content__error__body">
              <img src="@/assets/images/transfer/wait.svg" alt="deny" />
              <div class="transfer__form-content__error gift">
                <span>{{ errorGift.message }}</span>
              </div>
              <button class="wait-btn" @click="$router.push('/payment')">
                {{ $t('navigation.dropdown.refill') }}
              </button>
            </div>
          </template>
          <template v-else>
            <GiftList @updateGift="onSelectGift" :current="gift" />
            <Button class="transfer__form-btn" :disabled="!gift.robux_amount" @click="onSubmitGift">
              {{ $t('actions.continue') }}
            </Button>
          </template>
        </template>
      </div>
    </form-layout>
    <PromocodeModal v-if="isShowPromocodeModal" @close="isShowPromocodeModal = false" />
  </div>
</template>

<script>
import Loader from '@/components/LoaderBar/LoaderBar.vue'

import './transferMainForm.scss'
import PromoCodeInput from '@/components/PromoCodeInput/PromoCodeInput.vue'
import PromoCodeMixin from '@/components/PromoCodeInput/PromoCodeMixin'
import FormLayout from '@/components/Base/FormLayout/FormLayout.vue'
import Input from '@/components/Base/Input/Input.vue'
import Button from '@/components/Base/Button/Button.vue'
import PromocodeModal from '@/components/Modals/Promocode/PromocodeModal.vue'
import MethodSwitch from '@/components/MethodSwitch/MethodSwitch.vue'
import GiftList from '@/components/Gift/GiftList/GiftList.vue'
import windowSize from '@/mixins/windowSize'

const MIN_TO_BUY = 20
const MAX_TO_BUY = 5000
const METHODS = {
  TRANSFER: 'transfer',
  GIFT: 'gift',
  SUPER_PASS: 'superPass'
}
export default {
  name: 'TransferMainForm',
  components: {
    MethodSwitch,
    GiftList,
    Button,
    Input,
    FormLayout,
    PromoCodeInput,
    Loader,
    PromocodeModal
  },
  inject: ['currencyMap'],
  mixins: [PromoCodeMixin, windowSize],

  data() {
    return {
      METHODS,
      totalRobux: MIN_TO_BUY,
      totalRobuxText: MIN_TO_BUY,
      rates: {
        rub: 0,
        usd: 0,
        eur: 0
      },
      rbxAmount: 0,
      error: {
        status: false,
        show: false,
        message: ''
      },
      errorGift: {
        status: false,
        show: false,
        message: ''
      },
      loading: [],
      minToBuy: MIN_TO_BUY,
      maxToBuy: MAX_TO_BUY,
      isEnabledPromocode: false,
      isShowPromocodeModal: false,
      method: METHODS.TRANSFER,
      gift: {}
    }
  },
  created() {
    this.checkGetParams()
    const userId = localStorage.getItem('user_id') || 'default'

    this.loading.push(true)
    this.loading.push(true)

    fetch(`https://prices.backend-pier.com/get_rates/${userId}`)
      .then(res => res.json())
      .then(data => {
        this.rates = data.before

        this.loading.pop(true)
      })
      .catch(err => console.log(err))

    fetch('https://transfer.backend-pier.com/in_stock')
      .then(res => res.json())
      .then(data => {
        this.loading.pop(true)
        if (data.status) {
          this.rbxAmount = data.robux_amount
          this.validatePrice()
        }
      })
      .catch(err => console.log(err))
  },
  methods: {
    changeModal() {
      this.gift = {}
      if (+this.totalRobux === this.maxToBuy && this.isSuccessPromos) {
        this.error.status = false
        this.error.show = true
        this.error.message = this.$t('transfer.errors.robuxMaximun')

        return
      }
      if (this.error.show) {
        return
      }
      const promo = this.isSuccessPromos ? this.promoCode.value : null
      const bonus = this.isSuccessPromos ? this.promoCode.bonus : null
      const bonusForGamepass = this.isSuccessPromos ? this.promoCode.bonusForGamepass : null

      this.$analytics.send('purch_trans_1_calculator', {})
      this.$emit('changeModal', {
        modal: 'TransferCharacters',
        data: [this.totalRobux, this.getMoneyTotal, promo, bonus, bonusForGamepass]
      })
    },
    getBeforeRate() {
      const rateType = {
        rub: 'rate',
        usd: 'rate_usd',
        eur: 'rate_eur'
      }

      return this.rates[rateType[this.$store.state.currency]]
    },
    getMoneyTotal() {
      return Math.ceil(Number(this.totalRobux * this.getBeforeRate()) * 100) / 100
    },
    selectItem(price, event) {
      const target = event.target

      const selected = document.querySelector('.select__item.sel')
      if (selected) {
        selected.classList.remove('sel')
      }

      this.totalRobux = Number(price)
      this.totalRobuxText = Number(price)

      this.validatePrice()

      if (this.error.status === false) {
        return
      }

      target.classList.add('sel')
    },
    validatePrice(event) {
      const selected = document.querySelector('.select__item.sel')
      this.totalRobuxText = parseFloat(0)

      if (selected) {
        selected.classList.remove('sel')
      }
      if (isNaN(this.totalRobux)) {
        this.error.status = false
        this.error.show = true
        this.error.message = this.$t('transfer.errors.priceNotNumber')

        return
      } else if (this.totalRobux < this.minToBuy) {
        this.error.status = false
        this.error.show = true
        this.error.message = this.$t('transfer.errors.robuxMinimum', {
          robux: this.minToBuy
        })

        return
      } else if (!this.rbxAmount) {
        this.error.status = false
        this.error.show = true
        this.error.message = this.$t('transfer.errors.emptyBalance')

        return
      } else if (this.totalRobux > this.rbxAmount / 2) {
        this.error.status = false
        this.error.show = true
        this.error.message = this.$t('transfer.errors.halfBalance', {
          balance: (this.rbxAmount / 2).toFixed(2)
        })

        return
      } else if (this.totalRobux > 5000) {
        this.error.status = false
        this.error.show = true
        this.error.message = this.$t('transfer.errors.robuxMaximun')

        return
      }

      this.error.status = true
      this.error.show = false
      this.error.message = ''

      if (this.totalRobux) {
        this.totalRobuxText = parseFloat(this.totalRobux)
      }
    },
    isInteger(num) {
      return (num ^ 0) === num
    },
    onSelectGift(value) {
      this.gift = value
    },
    onSubmitGift() {
      this.errorGift.show = false
      this.errorGift.message = ''

      if (!this.$store.state.isAuth) {
        this.$emit('submitGift', { modal: 'SignIn' })
        return
      }

      if (this.gift.price[this.$store.state.currency] > this.$store.getters.balance) {
        this.$analytics.send('purch_gift_e1_payment_insufficient', {})

        this.errorGift.show = true
        this.errorGift.message = this.$t('gift.balanceError')
        return
      }

      this.$analytics.send('purch_gift_1_card', {})
      this.$emit('submitGift', this.gift)
    },
    checkGetParams() {
      const { gift, superPass } = this.$route.query

      if (gift) this.method = METHODS.GIFT
      if (superPass) this.onMethodChange(METHODS.SUPER_PASS)
    },
    onMethodChange(value) {
      if (value === METHODS.SUPER_PASS) {
        this.$emit('submitSuperPass', {
          modal: 'SuperPassGames'
        })

        return
      }
      this.method = value
    }
  },
  computed: {
    isSuccessPromos() {
      return this.promoCode.status === 'success'
    },
    methodsList() {
      return {
        transfer: {
          title: this.$t('transfer.titleSmall'),
          headerTitle: this.$t('transfer.title'),
          badge: null
        },
        gift: {
          title: this.$t('gift.titleSmall'),
          headerTitle: this.$t('gift.title'),
          badge: true
        },
        superPass: {
          title: this.$t('superPass.title'),
          headerTitle: this.$t('superPass.title'),
          badge: true
        }
      }
    },
    title() {
      return this.methodsList[this.method].headerTitle
    }
  },
  watch: {
    totalRobux() {
      if (this.promoCode.status === 'success') {
        this.promoCode.value = null
        this.promoCode.status = null
        this.promoCode.bonus = null
        this.promoCode.bonusForGamepass = null
      }
    }
  },
  mounted() {
    const hint = document.querySelector('.main__form.transfer__wrapper .hint')
    setTimeout(() => {
      if (hint) {
        if (document.body.clientWidth > 1395) hint.style.right = '0'
        if (document.body.clientWidth <= 1395) hint.style.bottom = '0'
      }
    }, 200)
  }
}
</script>
