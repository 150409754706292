<template>
  <div class="approved">
    <div class="approved__main">
      <Loader class="transferLoader" v-if="isLoadedTransfer === false" />
      <img v-if="status" src="./../../../assets/images/transfer/allow.svg" alt="allow" />
      <img
        v-if="status === false && isLowBalance === false"
        src="./../../../assets/images/transfer/deny.svg"
        alt="deny"
      />
      <img
        v-if="status === false && isLowBalance === true"
        src="./../../../assets/images/transfer/wait.svg"
        alt="wait"
      />
      <p v-if="status === false" class="error">{{ errorMessage }}</p>
      <p v-if="promoCode.status === 'error'" class="error">{{ promoCode.error }}</p>
      <button v-if="status === false && isPromoError === true" class="error-btn" @click="repeatTransfer">
        {{ $t('actions.tryAgain') }}
      </button>
      <button v-else-if="status === false && isLowBalance === false" class="error-btn" @click="goBack">
        {{ $t('actions.tryAgain') }}
      </button>
      <button v-if="status === false && isLowBalance === true" class="wait-btn" @click="buyOrder">
        {{ $t('payment.makeOrder') }}
      </button>
    </div>
  </div>
</template>

<script>
import './approved.scss'

import Loader from '@/components/LoaderBar/LoaderBar'
import PromoCodeMixin from '@/components/PromoCodeInput/PromoCodeMixin'

export default {
  name: 'Approved',
  components: {
    Loader
  },
  props: ['data'],
  mixins: [PromoCodeMixin],
  data() {
    return {
      isLoadedTransfer: false,
      order: null,
      status: null,
      errorMessage: '',
      isLowBalance: false,
      isPromoError: false
    }
  },
  methods: {
    changeModal() {
      this.$emit('changeModal', {
        modal: 'TransferOrderSuccesful'
      })
    },
    goBack() {
      this.$emit('changeModal', {
        modal: 'TransferPayment'
      })
    },
    async buyOrder() {
      this.isLoadedTransfer = false
      const currency = this.$store.state.currency.toUpperCase()
      const gamepass = this.data.method === 'gamepass' ? '&gamepass=true' : ''
      const promo = this.data.promo && this.$store.state.promoCode.bonus ? `&promo=${this.data.promo}` : ''

      if (promo) {
        const checkPromo = await this.checkPromo()
        if (!checkPromo) {
          this.errorMessage = this.$t('actions.promocode.error')
          return
        }
      }

      const requestUrl =
        `https://transfer.backend-pier.com/make_order?robux_amount=${this.data.totalRobux}&place_id=${this.data.placeId}&username=${this.data.nickname}&currency=${currency}&universe_id=${this.data.uid}${gamepass}` +
        promo
      const requestHeaders = new Headers({
        Authorization: `Bearer ${this.$cookies.get('access_token')}`
      })

      const requestParams = {
        method: 'GET',
        headers: requestHeaders
      }

      fetch(requestUrl, requestParams)
        .then(res => res.json())
        .then(data => {
          this.isLoadedTransfer = true
          // if (data.status) {
          //   this.$store.dispatch('buyOrder', { order: data.order, methodType: 1 })
          // }

          // TODO BACKEND FIX
          this.$store.dispatch('buyOrder', { order: data.order, methodType: 1 })

          if (data.status) {
            this.resetPromo()
            this.$analytics.send('perchase', {
              perchase_id: data.order?._id,
              userId: this.$store.state.account.userId
            })
          } else {
            const userId = this.$store.state.account.userId

            this.$store.dispatch('buyOrder', { order: data.order, methodType: 1 })
            if (data.data.includes(4)) {
              this.errorMessage = this.$t('transfer.errors.insufficientBalance')
              this.isLowBalance = true

              this.$analytics.send('purch_trans_e1_payment_insufficient', {})
              this.$analytics.send('perchase_error', {
                status: 'Недостаточно средств на балансе',
                userId
              })
            } else if (data.data.includes(5)) {
              this.errorMessage = this.$t('transfer.errors.notAuthorized')

              this.$analytics.send('perchase_error', {
                status: 'Вы не авторизованы',
                userId
              })
            } else if (data.data.includes(1)) {
              this.errorMessage = this.$t('transfer.errors.serverError')

              this.$analytics.send('perchase_error', {
                status: 'Ошибка на сервере',
                userId
              })
            } else if (data.data.includes(6)) {
              this.errorMessage = this.$t('transfer.errors.robuxNotAvailable')

              this.$analytics.send('perchase_error', {
                status: 'Количество робаксов недоступно для покупки',
                userId
              })
            }
          }
        })
        .catch(err => console.log(err))
    },
    async checkPromo() {
      const isPromoSuccess = await this.applyPromo(this.data.promo, this.data.totalRobux)

      if (!isPromoSuccess) {
        this.$store.commit('setPromoBonus', null)
        this.isLoadedTransfer = true
        this.status = false
        this.isPromoError = true
      }

      return isPromoSuccess
    },
    repeatTransfer() {
      window.location.replace('/')
    }
  },
  async mounted() {
    this.isLoadedTransfer = false
    const currency = this.$store.state.currency.toUpperCase()
    const gamepass = this.data.method === 'gamepass' ? '&gamepass=true' : ''

    const promo = this.data.promo && this.$store.state.promoCode.bonus ? `&promo=${this.data.promo}` : ''

    if (promo) {
      const checkPromo = await this.checkPromo()
      if (!checkPromo) {
        this.errorMessage = this.$t('actions.promocode.error')
        return
      }
    }
    const requestUrl =
      `https://transfer.backend-pier.com/make_order?robux_amount=${this.data.totalRobux}&place_id=${this.data.placeId}&username=${this.data.nickname}&currency=${currency}&universe_id=${this.data.uid}${gamepass}` +
      promo
    const requestHeaders = new Headers({
      Authorization: `Bearer ${this.$cookies.get('access_token')}`
    })

    const requestParams = {
      method: 'GET',
      headers: requestHeaders
    }

    fetch(requestUrl, requestParams)
      .then(res => res.json())
      .then(data => {
        this.isLoadedTransfer = true
        this.status = data.status
        this.resetPromo()

        if (data.status) {
          this.order = data.order

          setTimeout(() => {
            this.$emit('changeModal', {
              modal: 'TransferOrderSuccesful',
              data: [this.order]
            })
          }, 800)

          this.$analytics.send('perchase', {
            perchase_id: data.order?._id
          })
        } else {
          const userId = this.$store.state.account.userId

          if (data.data.includes(4)) {
            this.errorMessage = this.$t('transfer.errors.insufficientBalance')
            this.isLowBalance = true

            this.$analytics.send('purch_trans_e1_payment_insufficient', {})
            this.$analytics.send('perchase_error', {
              status: 'Недостаточно средств на балансе',
              userId
            })
          } else if (data.data.includes(5)) {
            this.errorMessage = this.$t('transfer.errors.notAuthorized')

            this.$analytics.send('perchase_error', {
              status: 'Вы не авторизованы',
              userId
            })
          } else if (data.data.includes(1)) {
            this.errorMessage = this.$t('transfer.errors.serverError')

            this.$analytics.send('perchase_error', {
              status: 'Ошибка на сервере',
              userId
            })
          } else if (data.data.includes(6)) {
            this.errorMessage = this.$t('transfer.errors.robuxNotAvailable')

            this.$analytics.send('perchase_error', {
              status: 'Количество робаксов недоступно для покупки',
              userId
            })
          }
        }
      })
      .catch(err => console.log(err))
  }
}
</script>
