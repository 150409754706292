<template>
  <div class="form-layout" :class="{ 'flat-footer': flatFooter, large }">
    <div class="form-layout__header">
      <div
        v-if="back"
        class="form-layout__back"
        @click="
          () => {
            back ? back() : null
          }
        "
      >
        <Icon name="/slider/arrow.svg" />
      </div>
      <div v-if="!hideClose" class="form-layout__close" @click="closeForm">
        <Icon name="/base/close.svg" />
      </div>
    </div>
    <div class="form-layout__body" :class="{ 'close-hide': hideClose }">
      <slot name="header" />
      <div class="form-layout__title-wrap">
        <div class="form-layout__title" :class="{ sm: smallTitle }" v-html="title"></div>
        <div v-if="subTitle" class="form-layout__sub-title" :class="{ sm: smallTitle }" v-html="subTitle"></div>
        <slot name="sub-title" />
      </div>
      <slot />
    </div>
  </div>
</template>
<script>
import Icon from '@/components/Base/Icon/Icon.vue'
import './form-layout.scss'
export default {
  name: 'FormLayout',
  props: {
    title: {
      value: String,
      default: ''
    },
    subTitle: {
      value: String,
      default: ''
    },
    showBack: {
      value: Boolean,
      default: false
    },
    hideClose: {
      value: Boolean,
      default: true
    },
    close: {
      value: Function,
      default: null
    },
    back: {
      value: Function
    },
    flatFooter: {
      value: Boolean,
      default: false
    },
    smallTitle: {
      value: Boolean,
      default: false
    },
    large: {
      value: Boolean,
      default: false
    }
  },
  components: { Icon },
  data() {
    return {
      windowHeight: window.innerHeight
    }
  },
  mounted() {
    window.addEventListener('resize', this.updateWindowHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWindowHeight)
  },
  methods: {
    closeForm() {
      this.close ? this.close() : this.$router.push('/')
    },
    updateWindowHeight() {
      this.windowHeight = window.innerHeight
    }
  },
  computed: {
    /* Фича не прижилась в UX
    maxHeight () {
      return {
        maxHeight: this.windowHeight - (this.windowHeight * 0.2) + 'px'
      }
    } */
  }
}
</script>
