<template>
  <div
    v-if="gift.enabled"
    class="gift-card"
    :class="{ active, disabled: !gift.stock }"
    @click="$emit('click')"
  >
    <div class="gift-card__header">
      <span class="price">{{ price }}</span>
      <span class="currency">{{ currency }}</span>
    </div>
    <div class="gift-card__body">
      <span>{{ gift.robux_amount }}</span>
      <Icon name="/base/robux.svg" />
    </div>
  </div>
</template>

<script>
import './giftCard.scss'
import Icon from '@/components/Base/Icon/Icon.vue'

export default {
  name: 'GiftCard',
  components: { Icon },
  props: {
    gift: {
      type: Object,
      require: true,
      default: () => {}
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    price() {
      return this.gift.price[this.$store.state.currency]
    },
    currency() {
      const currency = {
        rub: 'руб.',
        usd: '$',
        eur: '€'
      }
      return currency[this.$store.state.currency]
    }
  }
}
</script>
