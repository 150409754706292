<template>
  <div class="empty-list-info">
    <div class="empty-list-info__body">
      <img src=./images/placeholder_img.svg alt="icon" />
      <div class="empty-list-info__text">
        <slot name="text"></slot>
      </div>
      <div class="empty-list-info__footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import './emptyListInfo.scss'

export default {
  name: 'EmptyListInfo'
}
</script>
