import Vue from 'vue'
import Vuex from 'vuex'
import cookie from 'vue-cookies'

import router from './../router/index'
import { analytics as $analytics } from '@/utils/analytics'

Vue.use(Vuex)

const currencyMap = {
  rub: '₽',
  usd: '$',
  eur: '€'
}

export default new Vuex.Store({
  state: {
    showNotificationPremium: false,
    language: 'ru',
    currency: 'rub',
    ipCountry: 'ru',
    isAuth: false,
    showPreloader: true,
    syncCompleted: true,
    isOpenBurgerMenu: false,
    sign_in: false,
    sign_up: false,
    forget_password: false,
    change_password: false,
    isOpenUserDropMenu: false,
    isMobile: false,
    methods: {
      isPremiumSalesAvailable: false,
      isLogPassSalesAvailable: false,
      isFastPassSalesAvailable: false
    },
    account: {
      email: '',
      balance: {
        rub: 0,
        usd: 0,
        eur: 0
      },
      userId: '',
      username: '',
      accessToken: '',
      refreshToken: '',
      isVk: 0,
      avatarId: 1,
      avatarUrl: '',
      isPremium: false,
      country: 'ru'
    },
    payments: {
      order: null,
      methodType: 1
    },
    price: {},
    promoCode: {
      bonus: null
    },
    isScipGamepass: false
  },
  getters: {
    country: state => state.account.country?.toUpperCase(),
    currency: state => state.currency?.toUpperCase(),
    account: state => state.account,
    language: state => state.language,
    balance(state) {
      return state.account.balance[state.currency]
    },
    currencySign(state) {
      return currencyMap[state.currency]
    },
    getCurrencySign: state => currency => {
      return currencyMap[currency.toLowerCase()]
    },
    price(state) {
      const price = state.price

      return price.prices?.[price.levels?.[state.currency]?.[price.selectedLevel]] || 0
    },
    payments: state => state.payments
  },
  mutations: {
    setLanguage(state, lang) {
      state.language = lang
      localStorage.setItem('language', lang)
    },
    setCurrency(state, currency) {
      state.currency = currency
      localStorage.setItem('currency', currency)
    },
    stateSignIn(state) {
      state.sign_in = !state.sign_in
    },
    stateSignUp(state) {
      state.sign_up = !state.sign_up
    },
    stateForgetPassword(state) {
      state.forget_password = !state.forget_password
    },
    stateChangePassword(state) {
      state.change_password = !state.change_password
    },
    setPaymentData(state, order, type) {
      state.payments.order = order
      state.payments.methodType = type
      state.payments.afterOrder = !order
    },
    clearPaymentData(state) {
      state.payments = {}
    },
    setAccountData(state, data) {
      state.isAuth = true
      state.account.email = data.email
      state.account.balance = data.balance
      state.account.userId = data.user_id
      state.account.username = data.username
      state.account.isVk = data.is_vk
      state.account.country = data.country

      if (state.account.isVk) {
        state.account.avatarUrl = data.avatar
      } else {
        state.account.avatarId = data.avatar
      }

      localStorage.setItem('user_id', data.user_id)
      state.syncCompleted = true
    },
    setTokens(state, tokens) {
      state.account.accessToken = tokens.access
      state.account.refreshToken = tokens.refresh

      cookie.set('access_token', tokens.access, '1d')
      cookie.set('refresh_token', tokens.refresh, '2d')
    },
    setScipGamepass(state, data) {
      state.isScipGamepass = data
    },
    removeAccountData(state) {
      state.account = {}
      state.isAuth = false
      state.showUserDropMenu = false

      localStorage.removeItem('user_id')
    },
    removeTokens(state) {
      cookie.remove('access_token')
      cookie.remove('refresh_token')
    },
    toggleNotification(state) {
      state.showNotificationPremium = false
      setTimeout(() => {
        state.showNotificationPremium = true
      }, 50)
      setTimeout(() => {
        state.showNotificationPremium = false
      }, 5000)
    },
    setPromoBonus(state, bonus) {
      state.promoCode.bonus = bonus
    }
  },
  actions: {
    showNotificationPremium(ctx) {
      ctx.commit('toggleNotification')
    },
    signIn({ commit }, data) {
      commit('setAccountData', data.data)
      commit('setTokens', data.auth_token)
    },
    authorization({ commit }, token) {
      const requestHeaders = new Headers({
        Authorization: `Bearer ${token}`
      })

      const requestParams = {
        method: 'GET',
        headers: requestHeaders
      }

      fetch('https://users.backend-pier.com/api/v1/user/profile', requestParams)
        .then(res => res.json())
        .then(data => {
          if (data.status) {
            commit('setAccountData', data.data)
            $analytics.setUser(data.data.user_id)
          }
        })
        .catch(err => console.log(err))
    },
    logOut({ commit }, homeRedirect = true) {
      const requestHeaders = new Headers({
        Authorization: `Bearer ${cookie.get('access_token')}`
      })

      const requestParams = {
        method: 'POST',
        headers: requestHeaders
      }

      fetch('https://users.backend-pier.com/api/v1/user/logout', requestParams)
        .then(res => res.json())
        .then(data => {
          if (data.status) {
            commit('removeAccountData')
            commit('removeTokens')

            if (homeRedirect) router.push('/')
          }
        })
        .catch(err => console.log(err))
    },
    buyOrder({ commit }, { order, methodType }) {
      commit('setPaymentData', order, methodType)
      router.push('/payment')
    }
  },
  modules: {}
})
