<template>
  <div class="games transfer__wrapper">
    <form-layout :small-title="true" :title="$t('superPass.pass.title')" :back="goBack">
      <div class="transfer__form">
        <div class="transfer__form-games-list">
          <template v-if="passesList.length">
            <div v-for="pass in passesList" :key="pass._id" @click="selectPass(pass)">
              <PassCard :pass="pass" :current="currentPass" />
            </div>
          </template>
          <EmptyListInfo v-else>
            <template #text>
              <span v-if="search">
                {{ $t('superPass.empty.passSearch') }}
              </span>
              <span v-else>
                {{ $t('superPass.empty.pass') }}
              </span>
            </template>
          </EmptyListInfo>
        </div>
        <Input class="transfer__form-search-input" v-if="isShowSearch" type="text" v-model="search" :light="true" />
        <div class="transfer__form-footer">
          <Button
            class="transfer__form-search-btn"
            left-icon="/base/search.svg"
            @click="isShowSearch = !isShowSearch"
          />
          <Button class="transfer__form-btn" :disabled="!currentPass.name" @click="changeModal">
            {{ $t('actions.continue') }}
          </Button>
        </div>
      </div>
    </form-layout>
  </div>
</template>

<script>
import './superPassPasses.scss'

// import Loader from '@/components/LoaderBar/LoaderBar'
import Button from '@/components/Base/Button/Button.vue'
import FormLayout from '@/components/Base/FormLayout/FormLayout.vue'
import PassCard from '@/components/PassCard/PassCard.vue'
import Input from '@/components/Base/Input/Input.vue'
import EmptyListInfo from '@/components/EmptyListInfo/EmptyListInfo.vue'
import { openChat } from '@/utils/chat'

export default {
  name: 'SuperPassPasses',
  components: {
    EmptyListInfo,
    Input,
    PassCard,
    Button,
    FormLayout
    // Loader
  },
  props: {
    passes: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isLoadedTransfer: true,
      currentPass: {},
      isShowSearch: false,
      search: ''
    }
  },
  computed: {
    passesList() {
      const passes = this.passes.filter(pass => pass.enabled)
      if (this.search) {
        return passes.filter(pass => pass.name.toLowerCase().includes(this.search.toLowerCase()))
      } else {
        return passes
      }
    }
  },
  methods: {
    openChat,
    changeModal() {
      this.$store.commit('setScipGamepass', false)

      this.$analytics.send('purch_super_2_pass', {})

      this.$emit('changeModal', {
        modal: 'TransferCharacters',
        pass: this.currentPass
      })
    },
    goBack() {
      this.$emit('changeModal', {
        modal: 'SuperPassGames',
        data: []
      })
    },
    selectPass(pass) {
      this.currentPass = pass
    }
  }
}
</script>
