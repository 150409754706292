<template>
  <Modal class="gift-modal" icon="box" :title="$t('modals.gift.title')" @close="$emit('close')">
    <template #text>
      <div class="gift-modal__text">
        <span>
          {{ $t('modals.gift.text') }}
        </span>
      </div>
      <div class="gift-modal__copy">
        <div class="gift-modal__code">
          <span class="code">{{ giftCode }}</span>
        </div>

        <Button left-icon="/base/copy.svg" @click="copyText(giftCode)" />
      </div>
      <div v-if="isShowInstruction" class="gift-modal__instruction">
        <span>Внимательно ознакомьтесь с видео-инструкцией</span>
        <picture>
          <source srcset="./gif/instruction-mobile.gif" media="(max-width: 599px)" />
          <source srcset="./gif/instruction.gif" media="(min-width: 600px)" />
          <img src="./gif/instruction.gif" alt="Responsive Image" />
        </picture>
        <span>
          Перейдите на сайт Roblox и выполните все действия, указанные в видео-инструкции
        </span>
      </div>
    </template>
    <template #footer>
      <Button right-icon="/transfer/arrow-right.svg" @click="goToRoblox">
        {{ $t('modals.gift.submit') }}
      </Button>
    </template>
  </Modal>
</template>

<script>
import './giftModal.scss'
import Modal from '@/components/Base/Modal/modal.vue'
import Button from '@/components/Base/Button/Button.vue'

import { copyText } from '@/utils/copyText'

export default {
  name: 'GiftModal',
  components: {
    Button,
    Modal
  },
  props: {
    giftCode: {
      type: String
    },
    robux: {
      type: Number
    }
  },
  computed: {
    isShowInstruction() {
      return +this.robux === 373
    }
  },
  methods: {
    copyText,
    goToRoblox() {
      window.open('https://www.roblox.com/redeem', '_blank')
    }
  }
}
</script>
