<template>
  <div class="gamepass-instruction transfer__wrapper">
    <form-layout :large="true" :small-title="true" :title="pageTitle" :sub-title="pageSubTitle" :back="goToPlace">
      <div class="transfer-instruction-gamepass">
        <Loader v-if="loading" />

        <div class="hint" :class="{ active: hint }">
          <div class="hint-border">
            <div class="hint__content">
              <p class="hint__content__title">{{ $t('general.warning') }}</p>
              <p class="hint__content__text">
                {{ hintGamepassError ? $t('transfer.gamepass.checkData') : $t('transfer.gamepass.serverError') }}
              </p>
            </div>
          </div>
        </div>
        <!--        <div class="transfer-instruction-gamepass__title" v-if="step === 'create'">{{ $t('transfer.gamepass.creating') }} <span class="blue">GAME PASS</span></div>-->
        <!--        <div class="transfer-instruction-gamepass__title" v-else>{{ $t('transfer.gamepass.settingUp') }} <span class="green">GAME PASS</span></div>-->

        <!--        <div class="transfer-instruction-gamepass__subtitle" v-if="step === 'create'" v-html="$t('transfer.gamepass.createGamepass')"></div>-->
        <!--        <div class="transfer-instruction-gamepass__subtitle" v-else v-html="$t('transfer.gamepass.checkInstruction', {price: totalRobux})"></div>-->
        <!--        <div class="transfer-instruction-gamepass__content">-->
        <!--          <div class="transfer-instruction-gamepass__content__wrapper">-->
        <!--            <div class="transfer-instruction-gamepass__content__wrapper__creating" v-if="step === 'create'">-->
        <!--              <TransferInstructionGamepassCreating :place="data.placeName" />-->

        <!--              <a :href="`https://create.roblox.com/creations/experiences/${data.uid}/passes/create`" target="_blank" class="transfer-instruction-gamepass__content__wrapper__button orange" v-if="!isCreated" @click="createGamepass">-->
        <!--                {{ $t('actions.create') }}-->
        <!--                <img src="@/assets/images/transfer/arrow-right.svg" alt="arrow-right" srcset="">-->
        <!--              </a>-->

        <!--              <div class="transfer-instruction-gamepass__content__wrapper__button ready" v-else @click="submit">-->
        <!--                <img src="@/assets/images/transfer/checkmark.svg" alt="checkmark" srcset="">-->
        <!--                {{ $t('actions.ready') }}-->
        <!--              </div>-->
        <!--            </div>-->

        <!--            <div class="transfer-instruction-gamepass__content__wrapper__edit" v-else>-->
        <!--              <TransferInstructionGamepassEdit :price="totalRobuxFromUser" />-->

        <!--              <a :href="`https://create.roblox.com/creations/experiences/${data.uid}/passes/${gamepassId}/sales`" target="_blank" class="transfer-instruction-gamepass__content__wrapper__button orange" v-if="!isEdited" @click="editGamepass">-->
        <!--                {{ $t('actions.edit') }}-->
        <!--                <img src="@/assets/images/transfer/arrow-right.svg" alt="arrow-right" srcset="">-->
        <!--              </a>-->

        <!--              <div class="transfer-instruction-gamepass__content__wrapper__button ready" v-else @click="submit">-->
        <!--                <img src="@/assets/images/transfer/checkmark.svg" alt="checkmark" srcset="">-->
        <!--                {{ $t('actions.ready') }}-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="transfer-instruction-gamepass__count">
          <p>{{ $t('transfer.gamepass.setPrice') }}</p>
          <small>{{ totalRobux.toLocaleString('ru-RU') }} R$</small>
        </div>
        <div class="transfer-instruction-gamepass__instruction-video-wrapper">
          <template v-if="this.step === 'edit'">
            <div class="totalRobux">{{ totalRobux.toLocaleString('ru-RU') }}</div>
            <div class="totalRobuxFromUser">{{ totalRobuxFromUser.toLocaleString('ru-RU') }}</div>
          </template>
          <img
            class="transfer-instruction-gamepass__instruction-video"
            :class="screenWidth < 790 && 'mobile'"
            :src="currentInstructionVideo"
            alt="instruction"
          />
        </div>
        <Button
          class="transfer-instruction-gamepass__content__wrapper__button123"
          :left-icon="submitBtnLeftIcon"
          :right-icon="submitBtnRightIcon"
          @click="toggleGamepass"
        >
          {{ submitBtnText }}
        </Button>
      </div>
    </form-layout>
  </div>
</template>

<script>
import './transferInstructionGamepass.scss'

import Loader from '@/components/LoaderBar/LoaderBar.vue'
import FormLayout from '@/components/Base/FormLayout/FormLayout.vue'
import Button from '@/components/Base/Button/Button.vue'
import windowSize from '@/mixins/windowSize'

export default {
  name: 'TransferInstructionGamepass',
  props: ['data'],
  mixins: [windowSize],
  components: {
    Button,
    FormLayout,
    Loader
  },
  data() {
    return {
      step: 'create',
      isCreated: false,
      isEdited: false,
      loading: true,
      hint: false,
      hintGamepassError: false,
      gamepassId: null
    }
  },
  created() {
    this.loading = true
    if (this.$store.state.isScipGamepass) {
      this.$emit('changeModal', {
        modal: 'TransferPrivatPlace'
      })
      return
    }

    this.getGamepasses().then(data => {
      this.loading = false

      if (data.data.length) {
        const gamepassItem = data.data[0]
        if (gamepassItem?.price === this.totalRobux) {
          this.$store.commit('setScipGamepass', true)

          this.$analytics.send('purch_trans_4_gamepass', {})
          this.$emit('changeModal', {
            modal: 'TransferPayment'
          })
        }
        this.step = 'edit'
        this.gamepassId = data.data[0].id
      } else this.step = 'create'
    })
  },
  methods: {
    goToPlace() {
      this.$emit('changeModal', {
        modal: 'TransferPrivatPlace'
      })
    },
    getGamepasses() {
      return fetch(`https://transfer.backend-pier.com/get_passes/${this.data.uid}`)
        .then(res => res.json())
        .catch(err => console.log(err))
    },
    createGamepass() {
      this.isCreated = true
    },
    editGamepass() {
      this.isEdited = true
    },
    toggleGamepass() {
      if (this.step === 'create') {
        if (!this.isCreated) {
          this.createGamepass()
          window.open(
            `https://create.roblox.com/dashboard/creations/experiences/${this.data.uid}/passes/create`,
            '_blank'
          )
          return
        }
        this.submit()
      } else {
        if (!this.isEdited) {
          this.editGamepass()
          window.open(
            `https://create.roblox.com/dashboard/creations/experiences/${this.data.uid}/passes/${this.gamepassId}/sales`,
            '_blank'
          )
          return
        }
        this.submit()
      }
    },
    showHint(ganepassError = true) {
      this.isCreated = false
      this.isEdited = false
      this.hint = true
      this.hintGamepassError = ganepassError
      setTimeout(() => {
        this.hint = false
      }, 4000)
    },
    submit() {
      this.loading = true

      this.getGamepasses().then(data => {
        this.loading = false
        // Проверка на серверную ошибку
        if (!data?.data?.length) {
          this.showHint(false)
          return
        }

        if (!this.isEdited) {
          if (data.data.length) {
            this.gamepassId = data.data[0]?.id
            this.step = 'edit'
            return
          }

          this.showHint()
          return
        }
        if (data.data.length && data.data[0].price === this.totalRobux) {
          this.gamepassId = data.data[0].id
          this.$analytics.send('purch_trans_4_gamepass', {})

          this.$emit('changeModal', {
            modal: 'TransferPayment'
          })
        } else this.showHint()
      })
    }
  },
  computed: {
    totalRobuxFromUser() {
      return this.data.bonusForGamepass
        ? Number(this.data.totalRobux) + Number(this.data.bonusForGamepass)
        : this.data.totalRobux
    },
    totalRobux() {
      return Math.round(this.totalRobuxFromUser / 0.7)
    },
    pageTitle() {
      const title =
        this.step === 'create' ? this.$t('transfer.gamepass.creating') : this.$t('transfer.gamepass.settingUp')
      return !this.loading ? title + ' GAME PASS' : ''
    },
    pageSubTitle() {
      const subtitle =
        this.step === 'create'
          ? this.$t('transfer.gamepass.createGamepass')
          : this.$t('transfer.gamepass.checkInstructionNew')
      return !this.loading ? subtitle : ''
    },
    submitBtnText() {
      const create = !this.isCreated ? this.$t('actions.create') : this.$t('actions.ready')
      const edit = !this.isEdited ? this.$t('actions.edit') : this.$t('actions.ready')
      return this.step === 'create' ? create : edit
    },
    submitBtnLeftIcon() {
      const create = this.isCreated && '/transfer/checkmark.svg'
      const edit = this.isEdited && '/transfer/checkmark.svg'
      return this.step === 'create' ? create : edit
    },
    submitBtnRightIcon() {
      const create = !this.isCreated && '/transfer/arrow-right.svg'
      const edit = !this.isEdited && '/transfer/arrow-right.svg'
      return this.step === 'create' ? create : edit
    },
    currentInstructionVideo() {
      const isMobile = this.screenWidth < 790
      const create = isMobile
        ? require('@/assets/gif/Pier_pass_create_mobile_3.gif')
        : require('@/assets/gif/Pier_pass_create_desktop_2.gif')
      const edit = isMobile
        ? require('@/assets/gif/Pier_pass_config_mobile_transparent_3.gif')
        : require('@/assets/gif/Pier_pass_config_desktop_transparent_2.gif')
      return this.step === 'create' ? create : edit
    }
  }
}
</script>
