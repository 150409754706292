<template>
  <div class="character transfer__wrapper">
    <form-layout
      :small-title="true"
      :title="$t('transfer.charactersTitle')"
      :sub-title="$t('transfer.charactersSubTitle')"
      :back="goBack"
    >
      <div class="transfer__form">
        <div class="transfer__form-content">
          <Input
            v-model.trim="nickname"
            icon="/base/user.svg"
            :placeholder="$t('transfer.nickname')"
            @input="findUsers"
          />
          <RecentAccounts :roblox-nickname="nickname" @onSelect="selectRecentUser" />
          <div class="transfer__form-characters onfw">
            <Loader v-if="isLoadedTransfer === false" />
            <div
              v-for="(value, key) in characters"
              :key="key"
              @click="selectCharacter(key, value.name, $event)"
              class="transfer__form-characters__item"
            >
              <p class="transfer__form-characters__item-title">
                {{ value.name }}
              </p>
              <img :src="value.imageUrl" alt="" />
            </div>
            <div class="transfer__form-characters-not" v-if="characters.length === 0">
              <p>{{ $t('transfer.notFound') }}</p>
            </div>
          </div>
        </div>
        <Button class="transfer__form-btn" :disabled="!checked" @click="changeModal">
          {{ $t('actions.continue') }}
        </Button>
      </div>
    </form-layout>
  </div>
</template>

<script>
import './transferCharacters.scss'

import Loader from '@/components/LoaderBar/LoaderBar'
import FormLayout from '@/components/Base/FormLayout/FormLayout.vue'
import Button from '@/components/Base/Button/Button.vue'
import Input from '@/components/Base/Input/Input.vue'
import RecentAccounts from '@/components/RecentAccounts/RecentAccounts.vue'

export default {
  name: 'TransferCharacters',
  components: {
    Input,
    Button,
    FormLayout,
    Loader,
    RecentAccounts
  },
  props: ['isSuperPass'],

  data() {
    return {
      isLoadedTransfer: true,
      robloxUserId: '',
      nickname: '',
      choosedNickname: '',
      isSelectCharacter: false,
      isFilled: false,
      characters: [],
      interval: null
    }
  },
  computed: {
    checked() {
      if (this.isSelectCharacter) return !this.isFilled
      return this.isFilled
    }
  },
  methods: {
    changeModal() {
      const modal = this.isSuperPass ? 'SuperPassPurchase' : 'TransferPrivatPlace'
      const event = this.isSuperPass ? 'purch_super_3_nickname' : 'purch_trans_2_nickname'

      this.$store.commit('setScipGamepass', false)
      this.$analytics.send(event, {})

      this.$emit('changeModal', {
        modal,
        data: [this.robloxUserId, this.choosedNickname]
      })
    },
    goBack() {
      const modal = this.isSuperPass ? 'SuperPassPasses' : 'MainForm'
      this.$emit('changeModal', {
        modal,
        data: [this.robloxUserId, this.choosedNickname]
      })
    },
    selectCharacter(id, nickname, event) {
      if (this.isSelectCharacter) {
        const character = document.querySelector('.transfer__form-characters__item.select-ch')
        character.classList.remove('select-ch')
      }
      const target = event.target.closest('.transfer__form-characters__item')
      target.classList.add('select-ch')
      this.robloxUserId = id
      this.choosedNickname = nickname
      this.isSelectCharacter = true
    },
    findUsers() {
      this.isSelectCharacter = false
      clearTimeout(this.interval)

      this.interval = setTimeout(() => {
        if (this.nickname.length < 3) {
          this.characters = []
          return
        }

        this.isLoadedTransfer = false

        fetch(`https://transfer.backend-pier.com/search_users/${this.nickname}`)
          .then(res => res.json())
          .then(data => {
            this.isLoadedTransfer = true

            if (data.status) {
              this.characters = data.data
            } else {
              this.characters = []
            }
          })
          .catch(err => console.log(err))
      }, 1000)
    },
    selectRecentUser(nickname) {
      this.nickname = nickname
      this.findUsers()
    }
  }
}
</script>
