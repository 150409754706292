<template>
  <div class="progress">
    <div
      class="progress-bar progress-bar-striped progress-bar-animated"
      :class="[dynamicColor, { active: percentage !== 100 }]"
      role="progressbar"
      aria-valuemin="0"
      aria-valuemax="100"
      :aria-valuenow="percentage"
      :style="{ width: `${percentage}%` }"
    ></div>
    <span class="progress__description">{{ description }}</span>
  </div>
</template>

<script lang="ts">
import './progressBar.scss'

export default {
  name: 'ProgressBar',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    percentage: {
      type: Number,
      default: 0
    },
    description: {
      type: String,
      default: ''
    }
  },
  computed: {
    dynamicColor() {
      const ranges = {
        '0-25': 'class-0-25',
        '25-50': 'class-25-50',
        '50-75': 'class-50-75',
        '75-100': 'class-75-100',
        100: 'class-100'
      }

      if (this.percentage < 0 || this.percentage > 100) return ''

      if (+this.percentage === 100) {
        return ranges['100']
      }

      const rangeKey = Object.keys(ranges).find(key => {
        const [min, max] = key.split('-').map(Number)
        return this.percentage >= min && this.percentage < max
      })

      return rangeKey ? ranges[rangeKey] : ''
    }
  }
}
</script>
