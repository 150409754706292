<template>
  <div class="time-info transfer__wrapper">
    <form-layout :small-title="true" :title="$t('superPass.timeInfo.title')" :close="close">
      <div class="transfer__form">
        <div class="transfer__form-body">
          <div class="transfer__form-cover">
            <img src="./images/placeholder_img.svg" alt="cover" />
          </div>
          <div class="transfer__form-description" v-html="$t('superPass.timeInfo.description')" />
        </div>
        <div class="transfer__form-footer">
          <Button class="transfer__form-btn" @click="close">
            {{ $t('superPass.timeInfo.anotherMethod') }}
          </Button>
        </div>
      </div>
    </form-layout>
  </div>
</template>

<script>
import './superPassTimeInfo.scss'

import FormLayout from '@/components/Base/FormLayout/FormLayout.vue'
import Button from '@/components/Base/Button/Button.vue'

export default {
  name: 'SuperPassTimeInfo',
  components: {
    FormLayout,
    Button
  },
  methods: {
    close() {
      this.$emit('changeModal', {
        modal: 'MainForm',
        data: []
      })
    }
  }
}
</script>
