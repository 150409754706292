<template>
  <div class="transfer">
    <h1 class="seo-title">Покупка методом трансфер</h1>
    <Auth />
    <div class="wrapper">
      <Navigation />
    </div>
    <Loader v-if="loading" />
    <keep-alive>
      <TransferMainForm
        v-if="currentTransfer === 'MainForm'"
        @changeModal="changeTransfer"
        @submitGift="submitGift"
        @submitSuperPass="submitSuperPass"
      />
    </keep-alive>
    <TransferCharacters
      v-if="currentTransfer === 'TransferCharacters'"
      @changeModal="changeTransfer"
      :is-super-pass="data.superPass"
      :data="data"
    />
    <SuperPassTimeInfo v-if="currentTransfer === 'SuperPassTimeInfo'" @changeModal="changeTransfer" />
    <keep-alive>
      <SuperPassGames v-if="currentTransfer === 'SuperPassGames'" @changeModal="changeTransfer" :data="data" />
    </keep-alive>
    <SuperPassPasses
      v-if="currentTransfer === 'SuperPassPasses'"
      @changeModal="changeTransfer"
      :passes="data.game.passes"
    />
    <SuperPassPurchase
      v-if="currentTransfer === 'SuperPassPurchase'"
      @changeModal="changeTransfer"
      :data="data"
      :game="data.game"
      :pass="data.pass"
    />
    <TransferPrivatPlace
      v-if="currentTransfer === 'TransferPrivatPlace'"
      @changeModal="changeTransfer"
      v-bind:robloxUserId="data.robloxUserId"
    />
    <TransferInstructionGamepass
      v-if="currentTransfer === 'TransferInstructionGamepass'"
      @changeModal="changeTransfer"
      :data="data"
    />
    <Payment v-if="currentTransfer === 'TransferPayment'" @changeModal="changeTransfer" :data="data" />
    <SignIn
      v-if="currentTransfer === 'SignIn'"
      isStep
      @close="$router.push('/')"
      @back="signInBack"
      @success="signInSuccess"
      @createAccount="currentTransfer = 'SignUp'"
    />
    <SignUp
      v-if="currentTransfer === 'SignUp'"
      isStep
      @close="$router.push('/')"
      @back="currentTransfer = 'SignIn'"
      @success="currentTransfer = 'TransferPayment'"
    />
    <Approved v-if="currentTransfer === 'TransferApproved'" @changeModal="changeTransfer" :data="data" />
    <OrderSuccesful v-if="currentTransfer === 'TransferOrderSuccesful'" @changeModal="changeTransfer" :data="data" />
  </div>
</template>

<script>
import './transfer.scss'

import Navigation from '@/components/Navigation/Navigation'
import TransferMainForm from '@/components/Transfer/transferMainForm/TransferMainForm'
import TransferCharacters from '@/components/Transfer/transferCharacters/TransferCharacters'
import TransferPrivatPlace from '@/components/Transfer/transferPrivatPlace/TransferPrivatPlace'
import TransferInstructionGamepass from '@/components/Transfer/transferInstructionGamepass/TransferInstructionGamepass'
import Payment from '@/components/Transfer/transferPayment/Payment'
import Auth from '@/components/Auth/Auth'
import Approved from '@/components/Transfer/transferApproved/Approved'
import OrderSuccesful from '@/components/Transfer/transferOrderSuccesful/OrderSuccesful'
import Loader from '@/components/LoaderBar/LoaderBar.vue'
import SignIn from '@/components/SignIn/SignIn.vue'
import SignUp from '@/components/SignUp/SignUp.vue'
import SuperPassGames from '@/components/SuperPass/superPassGames/SuperPassGames'
import SuperPassPasses from '@/components/SuperPass/superPassPasses/SuperPassPasses.vue'
import SuperPassPurchase from '@/components/SuperPass/superPassPurchase/SuperPassPurchase.vue'
import SuperPassTimeInfo from '@/components/SuperPass/superPassTimeInfo/SuperPassTimeInfo.vue'

export default {
  name: 'Transfer',
  components: {
    SuperPassTimeInfo,
    SuperPassPurchase,
    SuperPassPasses,
    Navigation,
    TransferMainForm,
    TransferCharacters,
    TransferPrivatPlace,
    TransferInstructionGamepass,
    Payment,
    Auth,
    Approved,
    OrderSuccesful,
    Loader,
    SignIn,
    SignUp,
    SuperPassGames
  },
  data() {
    return {
      currentTransfer: 'MainForm',
      data: {
        totalRobux: '',
        moneyTotal: '',
        robloxUserId: '',
        nickname: '',
        placeId: '',
        placeName: '',
        orders: [],
        uid: '',
        method: 'gamepass',
        gift: {},
        superPass: false,
        game: {},
        pass: null
      },
      isGiftNoneAuth: false,
      loading: true
    }
  },
  created() {
    const requestHeaders = new Headers({
      Authorization: `Bearer ${this.$cookies.get('access_token')}`
    })

    const requestParams = {
      method: 'GET',
      headers: requestHeaders
    }

    this.loading = true

    fetch('https://transfer.backend-pier.com/active_orders', requestParams)
      .then(res => res.json())
      .then(data => {
        this.loading = false
        if (data.status) {
          this.data.orders = data.orders

          if (this.data.orders.length > 0) {
            // Не отображать форму с активными заявками при повторных покупках
            // this.currentTransfer = 'TransferOrderSuccesful'
          }
        }
      })
      .catch(err => console.log(err))
  },
  methods: {
    changeTransfer(params) {
      this.isGiftNoneAuth = false

      if (this.currentTransfer === 'MainForm') {
        // Очистка методов
        this.data.gift = {}
        this.data.superPass = false

        this.data.totalRobux = params.data[0]
        this.data.moneyTotal = params.data[1]
        this.data.promo = params.data[2]
        this.data.bonus = params.data[3]
        this.data.bonusForGamepass = params.data[4]
      } else if (this.currentTransfer === 'TransferCharacters') {
        this.data.robloxUserId = params.data[0]
        this.data.nickname = params.data[1]

        if (!this.$store.state.isAuth && this.data.superPass) {
          this.currentTransfer = 'SignIn'
          return
        }
      } else if (this.currentTransfer === 'TransferPrivatPlace' && params.data) {
        this.data.placeId = params.data[0]
        this.data.placeName = params.data[1]
        this.data.uid = params.data[2]
      } else if (this.currentTransfer === 'TransferApproved' && params.data) {
        this.data.orders.unshift(params.data[0])
      } else if (this.currentTransfer === 'SuperPassGames' && params.game) {
        this.data.game = params.game
      } else if (this.currentTransfer === 'SuperPassPasses' && params.pass) {
        this.data.pass = params.pass
      }
      this.currentTransfer = params.modal
    },
    submitGift(gift) {
      if (gift.modal) {
        this.isGiftNoneAuth = true
        this.currentTransfer = gift.modal
        return
      }

      this.currentTransfer = 'TransferPayment'
      this.data.gift = gift
    },
    submitSuperPass(params) {
      this.data.superPass = true
      this.currentTransfer = params.modal
    },
    signInBack() {
      if (this.isGiftNoneAuth) {
        this.currentTransfer = 'MainForm'
        return
      }
      if (this.data.superPass) {
        this.currentTransfer = 'TransferCharacters'
        return
      }
      this.currentTransfer = 'TransferPayment'
    },
    signInSuccess() {
      if (this.isGiftNoneAuth) {
        this.currentTransfer = 'MainForm'
        return
      }
      if (this.data.superPass) {
        this.currentTransfer = 'SuperPassPurchase'
        return
      }
      this.currentTransfer = 'TransferPayment'
    }
  }
}
</script>
