<template>
  <div class="timer transfer__wrapper">
    <form-layout
      :small-title="true"
      :hide-close="true"
      :title="$t('superPass.timer.title')"
      :sub-title="$t('superPass.timer.subTitle')"
    >
      <div class="transfer__form">
        <div class="divider" />

        <div class="step">
          <div class="step__content">
            <div class="step__header">
              <div class="step__number">1</div>
              <div class="step__title">{{ $t('superPass.timer.step.first.title') }}</div>
            </div>
            <div class="step__progress">
              <ProgressBar :percentage="percentage" :description="statusMessages[0]" />
              <span class="description">{{ statusMessages[1] }}</span>
            </div>
          </div>
        </div>

        <div class="divider" />

        <div class="step" :class="{ disabled: isSecondStepDisabled }">
          <div class="step__content">
            <div class="step__header">
              <div class="step__number">2</div>
              <div class="step__title">{{ $t('superPass.timer.step.second.title') }}</div>
            </div>
            <div class="step__description">
              <span>{{ $t('superPass.timer.step.second.user') }}</span>
              <div class="step__user-name">
                <span>
                  {{ order.supplier_roblox_username }}
                </span>
              </div>
            </div>
            <div class="step__info-text">
              {{ $t('superPass.timer.step.second.action') }}
              <br />
              <strong>Join (Присоединиться)</strong>
            </div>
            <Button
              class="step__button"
              icon-type="solid"
              right-icon="/transfer/arrow-right.svg"
              @click="redirectToRoblox"
            >
              {{ $t('superPass.timer.step.second.redirectToAccount') }}
            </Button>
          </div>
        </div>
        <div class="transfer__form-footer">
          <div v-if="!isSecondStepDisabled" class="message" :class="{ error: remainingTimeInGame <= 60 }">
            <div class="message__icon">!</div>
            <div class="message__description">{{ $t('superPass.timer.warningText') }}</div>
            <div class="message__time">
              <span class="text">{{ $t('superPass.timer.left') }}</span>
              <span class="number">
                {{ convertSecondsToTime(remainingTimeInGame) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </form-layout>
  </div>
</template>

<script>
import ProgressBar from '@/components/ProgressBar/ProgressBar.vue'
import FormLayout from '@/components/Base/FormLayout/FormLayout.vue'
import Button from '@/components/Base/Button/Button.vue'

import { convertSecondsToTime } from '@/utils/time'
export default {
  name: 'SuperPassTimer',
  components: {
    ProgressBar,
    FormLayout,
    Button
  },
  inject: ['currencyMap'],

  props: {
    order: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      elapsedTime: 0,
      interval: null,
      elapsedTimeInGame: 0,
      intervalInGame: null
    }
  },
  computed: {
    isSecondStepDisabled() {
      return this.order?.status !== 'in_game'
    },
    startTime() {
      return new Date(this.order.date)?.getTime()
    },
    percentage() {
      if (!this.isSecondStepDisabled) return 100
      return +Math.min((this.elapsedTime / (15 * 60)) * 100, 100).toFixed(0)
    },
    remainingTime() {
      return Math.max(15 * 60 - this.elapsedTime, 0)
    },
    statusMessages() {
      if (!this.isSecondStepDisabled) {
        return [this.$t('superPass.timer.statuses.ready'), this.$t('superPass.timer.statuses.waiting')]
      }

      const timeLeft = this.remainingTime

      if (timeLeft > 800) {
        return [this.$t('superPass.timer.left') + ' ~15 минут', this.$t('superPass.timer.statuses.freeSoon')]
      }
      if (timeLeft > 600) {
        return [this.$t('superPass.timer.left') + ' ~10 минут', this.$t('superPass.timer.statuses.startingOrder')]
      }
      if (timeLeft > 180) {
        return [this.$t('superPass.timer.left') + ' ~5 минут', this.$t('superPass.timer.statuses.launchingGame')]
      }
      if (timeLeft < 180 && this.isSecondStepDisabled) {
        return [this.$t('superPass.timer.left') + ' ~3 минуты', this.$t('superPass.timer.statuses.preparingPass')]
      }

      return [this.$t('superPass.timer.statuses.ready'), this.$t('superPass.timer.statuses.waiting')]
    },
    startTimeInGame() {
      return new Date(this.order.in_game_date)?.getTime()
    },
    remainingTimeInGame() {
      return Math.max(5 * 60 - this.elapsedTimeInGame, 0)
    }
  },
  methods: {
    convertSecondsToTime,

    redirectToRoblox() {
      window.open(this.order?.supplier_roblox_url, '_blank')
    },
    updateTimer() {
      this.elapsedTime = Math.floor((Date.now() - this.startTime) / 1000)
      this.elapsedTimeInGame = Math.floor((Date.now() - this.startTimeInGame) / 1000)
    }
  },
  mounted() {
    this.interval = setInterval(this.updateTimer, 10)
    this.intervalInGame = setInterval(this.updateTimer, 10)
  },
  beforeDestroy() {
    clearInterval(this.interval)
    clearInterval(this.intervalInGame)
  }
}
</script>
<style lang="scss" scoped>
@import './superPassTimer.scss';
</style>
