<template>
  <div class="history">
    <div class="change-password" v-if="$store.state.change_password">
      <ChangePassword />
    </div>
    <div class="wrapper">
      <Navigation />
    </div>
    <SuperPassTimer v-if="currentPassOrder" :order="currentPassOrder" />

    <div v-else class="history-content">
      <Loader v-if="loading" />
      <Button size="md" @click="goToTransactions">
        {{ $t('history.pendingInfo') }}
      </Button>
      <div class="history-content__header">
        <div
          class="history-content__header__block"
          :class="{ active: currentTab === 'transfer' }"
          @click="currentTab = 'transfer'"
        >
          <p>{{ $t('general.transfer') }}</p>
          <small>{{ totalOrders.transfer }}</small>
        </div>
        <div
          class="history-content__header__block"
          :class="{ active: currentTab === 'gifts' }"
          @click="setTab('gifts')"
        >
          <p>{{ $t('general.gifts') }}</p>
          <small>{{ totalOrders.gifts }}</small>
        </div>
        <div
          class="history-content__header__block"
          :class="{ active: currentTab === 'superPass' }"
          @click="setTab('superPass')"
        >
          <p>{{ $t('general.superPass') }}</p>
          <small>{{ totalOrders.superPass }}</small>
        </div>
      </div>

      <!-- Table -->
      <div class="history-table">
        <Loader v-if="ordersLoading" />
        <!-- Header -->
        <div v-if="isBaseTab" class="history-table__header">
          <p class="id">ID</p>
          <p class="nickname">{{ $t('history.nickname') }}</p>
          <p class="sum-robux">{{ $t('history.sumRobux') }}</p>
          <p class="sum-rub">{{ $t('history.sumRub') }}</p>
          <p class="status">{{ $t('history.status') }}</p>
          <p class="description">{{ $t('history.description') }}</p>
          <p class="timer">{{ $t('history.time') }}</p>
        </div>
        <div
          v-if="currentTab === 'superPass'"
          class="history-table__header"
          :class="[`history-table__header--${currentTab}`]"
        >
          <p class="id">ID</p>
          <p class="nickname">{{ $t('history.nickname') }}</p>
          <p class="sum-robux">{{ $t('history.game') }}</p>
          <p class="sum-robux">{{ $t('history.pass') }}</p>
          <p class="sum-rub">{{ $t('history.sumRub') }}</p>
          <p class="status">{{ $t('history.status') }}</p>
          <p class="description">{{ $t('history.description') }}</p>
          <p class="timer">{{ $t('history.time') }}</p>
        </div>

        <!-- Rows -->
        <div class="history-table__rows">
          <template v-if="isBaseTab">
            <div v-for="order in currentOrders" :key="order._id" class="history-table__row">
              <p class="id">
                <span>{{ order._id.slice(-4) }}</span>
                <Button class="history-table__copy" left-icon="/base/copy.svg" @click="copyText(order._id)" size="sm">
                  id
                </Button>
              </p>
              <p v-if="order.gift_code" class="nickname">Подарочная карта</p>
              <p v-else class="nickname">{{ order.username }}</p>
              <p class="sum-robux">
                <template v-if="order.promo">
                  <span>{{ order.promo.robux_amount_no_promo }} R$</span>
                  <span v-if="order.promo" class="promo">+ {{ getPromoBonus(order.promo) }} R$</span>
                </template>
                <template v-else>
                  <span>{{ order.robux_amount }} R$</span>
                </template>
              </p>
              <p v-if="order.currency" class="sum-rub">
                {{ getOrderTotal(order) }}
                {{ $store.getters.getCurrencySign(order.currency.toLowerCase()) }}
              </p>
              <p v-else class="sum-rub">{{ order.amount[$store.state.currency].toFixed(2) }} ₽</p>
              <p class="status">
                <span class="error" v-if="order.status === 'error' || order.status === 'system_error'">
                  {{ $t('history.error') }}
                </span>
                <span class="waiting" v-if="['waiting', 'paid', 'code', 'robuxship'].includes(order.status)">
                  {{ $t('history.wait') }}
                </span>
                <span class="success" v-if="order.status === 'success'">
                  {{ $t('history.success') }}
                </span>
                <template v-if="order.gift_code">
                  <span class="success">
                    {{ $t('history.giftInfo') }}
                  </span>
                </template>
              </p>
              <p v-if="!order.gift_code" class="description">
                {{ getOrderDescription(order) }}
              </p>
              <template v-else>
                <p class="description gift">
                  {{ $t('order.descriptions.gift') }}
                  <a class="gift-open" @click="showGiftModal(order)">
                    {{ $t('actions.open') }}
                  </a>
                </p>
              </template>

              <p v-if="currentTab === 'transfer'" class="timer">{{ parseDate(order._id, 'orders') }}</p>
              <p v-else class="timer">{{ parseDate(order._id, 'giftOrders') }}</p>
            </div>
          </template>
          <template v-if="currentTab === 'superPass'">
            <div
              v-for="order in passOrders"
              :key="order._id"
              class="history-table__row"
              :class="[`history-table__row--${currentTab}`]"
            >
              <p class="id">
                <span>{{ order._id.slice(-4) }}</span>
                <Button class="history-table__copy" left-icon="/base/copy.svg" @click="copyText(order._id)" size="sm">
                  id
                </Button>
              </p>
              <p class="nickname">{{ order.username }}</p>
              <p class="nickname">{{ getGameInfo(order).gameName }}</p>
              <p class="nickname">{{ getGameInfo(order).passName }}</p>
              <p class="sum-rub">
                {{ getOrderTotal(order) }} {{ $store.getters.getCurrencySign(order.currency.toLowerCase()) }}
              </p>
              <p class="status">
                <span
                  class="error"
                  v-if="order.status === 'error' || order.status === 'system_error' || order.status === 'cancel'"
                >
                  {{ $t('history.error') }}
                </span>
                <span class="error pass" v-if="order.status === 'in_game'" @click="scrollToTop">
                  {{ $t('history.passClick') }}
                </span>
                <span class="waiting" v-if="['waiting', 'paid', 'code', 'robuxship'].includes(order.status)">
                  {{ $t('history.wait') }}
                </span>
                <span class="success" v-if="order.status === 'success'">
                  {{ $t('history.success') }}
                </span>
              </p>
              <p class="description" :class="{ error: order.status === 'in_game' }">
                {{ getOrderPassDescription(order) }}
              </p>
              <p class="timer">{{ parseDate(order._id, 'passOrders') }}</p>
            </div>
          </template>
        </div>
      </div>
      <!-- Footer -->
      <div class="history-footer" v-show="totalOrders[currentTab] > 0">
        <div class="history-footer__nav">
          <div
            class="history-footer__nav__left"
            v-on="currentPage - 1 <= 0 ? {} : { click: () => changeOrders(currentPage - 1, currentTab) }"
          >
            <img src="./../../assets/images/history/left-arrow.svg" alt="left" />
          </div>
          <div class="history-footer__nav__center">
            <p
              v-for="i in currentPaginationPages"
              :key="i"
              :class="{ active: i === currentPage }"
              @click="changeOrders(i, currentTab)"
            >
              {{ i }}
            </p>
            <p v-if="showEllipsisBefore">...</p>
            <p v-if="showLastPage" @click="currentPage = totalPages[currentTab]">
              {{ totalPages[currentTab] }}
            </p>
          </div>
          <div
            class="history-footer__nav__right"
            v-on="
              currentPage + 1 > totalPages[currentTab] ? {} : { click: () => changeOrders(currentPage + 1, currentTab) }
            "
          >
            <img src="./../../assets/images/history/right-arrow.svg" alt="right" />
          </div>
        </div>
        <div class="history-footer__info">
          <i18n path="history.orderCount" tag="p">
            <template v-slot:order>
              <span v-if="currentTab === 'transfer'">{{ orders.length }}</span>
              <span v-if="currentTab === 'gifts'">{{ giftOrders.length }}</span>
              <span v-if="currentTab === 'superPass'">{{ passOrders.length }}</span>
            </template>

            <template v-slot:length>
              {{ totalOrders[currentTab] }}
            </template>
          </i18n>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <GiftModal v-if="isShowGiftModal" :gift-code="gift.code" :robux="gift.robux" @close="isShowGiftModal = false" />
  </div>
</template>

<script>
import './history.scss'

import Navigation from '@/components/Navigation/Navigation'
import ChangePassword from '@/components/ChangePassword/ChangePassword'
import Loader from '@/components/LoaderBar/LoaderBar'
import Button from '@/components/Base/Button/Button.vue'
import GiftModal from '@/components/Modals/Gift/GiftModal.vue'
import { getPromoBonus } from '@/utils/promo'
import { copyText } from '@/utils/copyText'
import SuperPassTimer from '@/components/SuperPass/superPassTimer/superPassTimer.vue'

export default {
  name: 'History',
  components: {
    SuperPassTimer,
    GiftModal,
    Button,
    Navigation,
    ChangePassword,
    Loader
  },
  inject: ['currencyMap'],
  data() {
    return {
      loading: true,
      ordersLoading: false,
      totalPages: {
        transfer: 1,
        gifts: 1,
        superPass: 1
      },
      totalOrders: {
        transfer: 0,
        gifts: 0,
        superPass: 0
      },
      currentPage: 1,
      orders: [],
      giftOrders: [],
      passOrders: [],
      timers: [],
      games: [],
      isShowGiftModal: false,
      currentPassOrder: null,
      passStatusIntervalId: null,
      gift: {
        code: '',
        robux: 0
      },
      currentTab: 'transfer'
    }
  },
  created() {
    this.$store.dispatch('authorization', this.$cookies.get('access_token'))
    this.loading = true

    this.checkRouteParams()
    this.getGiftsHistory()
    this.getPassHistory()
    const requestHeaders = new Headers({
      Authorization: `Bearer ${this.$cookies.get('access_token')}`
    })

    const requestParams = {
      method: 'GET',
      headers: requestHeaders
    }

    fetch('https://transfer.backend-pier.com/get_history?count=7&page=1', requestParams)
      .then(res => res.json())
      .then(data => {
        if (data.status) {
          this.totalPages.transfer = data.total_pages
          this.totalOrders.transfer = data.total_orders
          this.orders = data.orders
          // this.orders.forEach((order) => {
          //   const orderDate = new Date(order.date)
          //   orderDate.setHours(orderDate.getHours() + 1)
          //   const delta = orderDate - Date.now()

          //   this.timers.push({ [order._id]: delta })
          // })

          // this.initTimers()
        }

        this.loading = false
      })
      .catch(err => console.log(err))
  },
  computed: {
    currentPaginationPages() {
      const indexes = []
      const maxDisplayedIndexes = 5
      const halfDisplayedIndexes = Math.floor(maxDisplayedIndexes / 2)

      let start = Math.max(this.currentPage - halfDisplayedIndexes, 1)
      const end = Math.min(start + maxDisplayedIndexes - 1, this.totalPages[this.currentTab])

      if (end - start < maxDisplayedIndexes - 1) {
        start = Math.max(end - maxDisplayedIndexes + 1, 1)
      }

      for (let i = start; i <= end; i++) {
        indexes.push(i)
      }

      return indexes
    },
    showEllipsisBefore() {
      return (
        (this.currentPaginationPages[0] > 1 || this.totalPages[this.currentTab] > 5) &&
        this.currentPage < this.totalPages[this.currentTab] - 2
      )
    },
    showLastPage() {
      return this.currentPaginationPages[this.currentPaginationPages.length - 1] < this.totalPages[this.currentTab]
    },
    currentOrders() {
      if (this.currentTab === 'gifts') {
        return this.giftOrders
      }
      return this.orders
    },
    isBaseTab() {
      return this.currentTab === 'transfer' || this.currentTab === 'gifts'
    }
  },
  methods: {
    copyText,
    getPromoBonus,
    getOrderTotal(order) {
      return order.amount[order.currency.toLowerCase()].toFixed(2)
    },
    getOrderDescription(order) {
      const code = order.code_description
      const texts = {
        0: this.$t('order.descriptions.wait'),
        1: this.$t('order.descriptions.notEnoughFunds'),
        2: this.$t('order.descriptions.systemError'),
        3: this.$t('order.descriptions.checkVip'),
        4: this.$t('order.descriptions.connectionError'),
        5: this.$t('order.descriptions.success'),
        6: this.$t('order.descriptions.checkGamepass')
      }

      return texts[code]
    },
    getOrderPassDescription(order) {
      const code = order.status
      const texts = {
        in_game: this.$t('order.descriptions.passInGame'),
        success: this.$t('order.descriptions.passSuccess'),
        cancel: this.$t('order.descriptions.passCancel'),
        error: this.$t('order.descriptions.passCancel'),
        paid: this.$t('order.descriptions.passWaiting')
      }

      return texts[code]
    },
    changeOrders(page, tab) {
      this.ordersLoading = true
      this.currentPage = page

      const requestHeaders = new Headers({
        Authorization: `Bearer ${this.$cookies.get('access_token')}`
      })

      const requestParams = {
        method: 'GET',
        headers: requestHeaders
      }

      if (tab === 'gifts') {
        this.getGiftsHistory(page)
        return
      }

      if (tab === 'superPass') {
        if (!this.passOrders.length) this.getPassHistory(page)
        return
      }

      fetch(`https://transfer.backend-pier.com/get_history?count=7&page=${page}`, requestParams)
        .then(res => res.json())
        .then(data => {
          if (data.status) {
            this.timers = []
            this.totalPages.transfer = data.total_pages
            this.totalOrders.transfer = data.total_orders
            this.orders = data.orders

            // this.orders.forEach((order) => {
            //   const orderDate = new Date(order.date)
            //   orderDate.setHours(orderDate.getHours() + 1)
            //   const delta = orderDate - Date.now()

            //   this.timers.push({ [order._id]: delta })
            // })

            // this.initTimers()

            this.ordersLoading = false
          } else {
            this.ordersLoading = false
          }
        })
        .catch(err => console.log(err))
    },
    getGiftsHistory(page = 1) {
      const requestHeaders = new Headers({
        Authorization: `Bearer ${this.$cookies.get('access_token')}`
      })

      const requestParams = {
        method: 'GET',
        headers: requestHeaders
      }

      fetch(`https://transfer.backend-pier.com/get_history_gifts?count=7&page=${page}`, requestParams)
        .then(res => res.json())
        .then(data => {
          if (data.status) {
            this.timers = []
            this.giftOrders = data.orders
            this.totalPages.gifts = data.total_pages
            this.totalOrders.gifts = data.total_orders

            this.ordersLoading = false
          } else {
            this.ordersLoading = false
          }
        })
        .catch(err => console.log(err))
    },
    getPassHistory(page = 1) {
      const requestHeaders = new Headers({
        Authorization: `Bearer ${this.$cookies.get('access_token')}`
      })

      const requestParams = {
        method: 'GET',
        headers: requestHeaders
      }

      fetch(`https://transfer.backend-pier.com/get_superpasses_history?count=7&page=${page}`, requestParams)
        .then(res => res.json())
        .then(data => {
          if (data.status) {
            this.timers = []
            this.passOrders = data.orders
            this.totalPages.superPass = data.total_pages
            this.totalOrders.superPass = data.total_orders

            this.ordersLoading = false
            this.checkActivePassOrder()
          } else {
            this.ordersLoading = false
          }
        })
        .catch(err => console.log(err))
    },
    checkActivePassOrder() {
      const activeStatuses = ['paid', 'in_game']

      this.passOrders.forEach(async item => {
        // Отображение таймера активного пасса
        if (activeStatuses.includes(item.status)) {
          if (!this.games.length) await this.getGames()

          const orderGame = this.games.find(game => game._id === item.game_id)
          // Если не требует захода на сервер - пропускаем
          if (!orderGame?.in_game) return

          this.currentPassOrder = item
          this.startCheckOrderPassStatus()
        }
      })

      // Сброс активного заказа пасса
      if (!activeStatuses.includes(this.passOrders[0].status) && this.currentPassOrder) {
        this.currentPassOrder = null
        clearInterval(this.passStatusIntervalId)
        window.location.reload()
      }
    },
    startCheckOrderPassStatus() {
      this.passStatusIntervalId = setInterval(this.getPassHistory, 30000)
    },
    initTimers() {
      this.timers.forEach(timer => {
        const key = Object.entries(timer)[0][0]

        const iid = setInterval(() => {
          timer[key] -= 1000

          if (timer[key] <= 0) {
            clearInterval(iid)
          }
        }, 1000)
      })
    },
    getTimer(id) {
      const rawTimer = this.timers.find(t => Object.keys(t)[0] === id)
      const rawDelta = Object.values(rawTimer)[0]

      if (rawDelta <= 0) {
        return '00:00:00'
      } else {
        const formatedHours = ('0' + String(Math.trunc(rawDelta / 1000 / 60 / 60))).slice(-2)
        const formatedMinutes = ('0' + String(Math.trunc((rawDelta / 1000 / 60) % 60))).slice(-2)
        const formatedSeconds = ('0' + String(Math.trunc(((rawDelta / 1000) % 60) % 60))).slice(-2)

        return `${formatedHours}:${formatedMinutes}:${formatedSeconds}`
      }
    },
    parseDate(id, orders) {
      const foundOrder = this[orders].find(order => order._id === id)
      const date = new Date(foundOrder?.finish_date || foundOrder?.date)

      const day = ('0' + date.getDate()).substr(-2)
      const month = ('0' + (date.getMonth() + 1)).substr(-2)
      const year = date.getFullYear()
      const hour = ('0' + date.getHours()).substr(-2)
      const minutes = ('0' + date.getMinutes()).substr(-2)

      return `${day}.${month}.${year} ${hour}:${minutes}`
    },
    goToTransactions() {
      window.open('https://www.roblox.com/transactions', '_blank')
    },
    showGiftModal(order) {
      this.gift.code = order.gift_code
      this.gift.robux = order.robux_amount
      this.isShowGiftModal = true
    },
    setTab(tab) {
      this.currentTab = tab

      if (tab === 'superPass') {
        this.getGames()
        if (!this.passOrders.length) this.getPassHistory()
        return
      }

      if (tab === 'gifts') {
        this.getGiftsHistory()
      }
    },
    getGameInfo(order) {
      const game = this.games.find(game => game._id === order?.game_id)
      if (!game) return {}
      const pass = game.passes.find(pass => pass._id === order?.superpass_id)

      return {
        gameName: game?.name,
        passName: pass?.name
      }
    },
    async getGames() {
      await fetch('https://transfer.backend-pier.com/superpasses')
        .then(res => res.json())
        .then(data => {
          if (data.games) {
            this.games = data.games.filter(game => game.enabled)
          }
        })
        .catch(err => console.log(err))
        .finally(() => {})
    },
    checkRouteParams() {
      const method = this.$route.query.method
      if (method) {
        this.setTab(method)
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  },
  beforeDestroy() {
    clearInterval(this.passStatusIntervalId)
  }
}
</script>
