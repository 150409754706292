<template>
  <div class="payment">
    <form-layout
      :small-title="true"
      :title="title"
      :sub-title="!isGiftMethod && $t('transfer.paymentSubTitle')"
      :back="goToTransferInstruction"
    >
      <div v-if="!isGiftMethod" class="payment__main">
        <div class="hint" :class="{ active: hint }">
          <div class="hint-border">
            <div class="hint__content">
              <p class="hint__content__title">
                {{ $t('transfer.gamepass.success') }}
              </p>
              <p class="hint__content__content">
                {{ $t('transfer.gamepass.successHint') }}
              </p>
            </div>
          </div>
        </div>
        <Input
          v-model="data.nickname"
          :disabled="true"
          icon="/transfer/people.svg"
          :placeholder="$t('transfer.nickname')"
        />
        <Input
          :value="data.totalRobux + ' R$'"
          :disabled="true"
          icon="/transfer/coin1.svg"
          :placeholder="$t('transfer.nickname')"
          :bonus="$store.state.promoCode.bonus ? `+${$store.state.promoCode.bonus} R$` : null"
        />
        <Input
          :value="data.moneyTotal().toFixed(2) + ` ${currencyMap[$store.state.currency]}`"
          :disabled="true"
          icon="/transfer/rub.svg"
          :placeholder="$t('transfer.nickname')"
        />
        <Alert state="warning" :title="$t('general.warning')" :info="$t('transfer.subTitle')" />
        <Button class="payment__main__btn-pay" @click="changeModal">
          {{ $t('payment.pay') }}
        </Button>
        <p class="payment__main__result">
          {{ $t('payment.toPay') }}:
          <span>
            {{ data.moneyTotal().toFixed(2) }}
            {{ currencyMap[$store.state.currency] }}
          </span>
        </p>
      </div>
      <div v-else class="payment__main">
        <Input :value="data.gift.robux_amount + ' R$'" :disabled="true" icon="/transfer/coin1.svg" />
        <Input
          :value="gift.price && gift.price.rub + ` ${currencyMap[$store.state.currency]}`"
          :disabled="true"
          icon="/transfer/rub.svg"
        />
        <Alert state="warning" :title="$t('general.warning')" :info="$t('gift.infoText')" />
        <Button class="payment__main__btn-pay" @click="changeModal">
          {{ $t('payment.pay') }}
        </Button>
        <p class="payment__main__result">
          {{ $t('payment.toPay') }}:
          <span>
            {{ gift.price && gift.price.rub }}
            {{ currencyMap[$store.state.currency] }}
          </span>
        </p>
      </div>
    </form-layout>
    <GiftModal v-if="isShowGiftModal" :gift-code="giftModal.code" :robux="giftModal.robux" @close="redirectToHistory" />
  </div>
</template>

<script>
import './payment.scss'
import FormLayout from '@/components/Base/FormLayout/FormLayout.vue'
import Input from '@/components/Base/Input/Input.vue'
import Button from '@/components/Base/Button/Button.vue'
import Alert from '@/components/Base/Alert/Alert.vue'
import GiftModal from '@/components/Modals/Gift/GiftModal.vue'

export default {
  name: 'Payment',
  props: ['data'],
  inject: ['currencyMap'],
  components: {
    GiftModal,
    Button,
    Input,
    FormLayout,
    Alert
  },
  data() {
    return {
      hint: false,
      isShowGiftModal: false,
      giftModal: { code: '', robux: 0 }
    }
  },
  computed: {
    isGiftMethod() {
      return this.data.gift.robux_amount
    },
    gift() {
      return this.data.gift
    },
    title() {
      return this.isGiftMethod ? this.$t('gift.title') : this.$t('transfer.title')
    }
  },
  methods: {
    changeModal() {
      let emitParams = {}
      emitParams = {
        modal: !this.$store.state.isAuth ? 'SignIn' : 'TransferApproved'
      }

      if (this.$store.state.isAuth) this.$analytics.send('purch_trans_5_payment_balance', {})

      if (this.isGiftMethod) {
        if (!this.$store.state.isAuth) this.$emit('changeModal', emitParams)
        this.sendGift()
        return
      }

      this.$emit('changeModal', emitParams)
    },
    goToTransferInstruction() {
      const modal = this.isGiftMethod ? 'MainForm' : 'TransferInstructionGamepass'
      this.$emit('changeModal', {
        modal
      })
    },
    showHint() {
      this.hint = true
      setTimeout(() => {
        this.hint = false
      }, 4000)
    },
    sendGift() {
      const { robux_amount: amount } = this.gift
      const currency = this.$store.state.currency.toUpperCase()

      const requestHeaders = new Headers({
        Authorization: `Bearer ${this.$cookies.get('access_token')}`
      })
      const requestParams = {
        method: 'GET',
        headers: requestHeaders
      }

      fetch(
        `https://transfer.backend-pier.com/make_order_gift?robux_amount=${amount}&currency=${currency}`,
        requestParams
      )
        .then(res => res.json())
        .then(data => {
          const { gift } = data

          if (gift?.gift_code) {
            this.$analytics.send('purch_gift_2_payment_balance', {})

            this.giftModal.code = gift.gift_code
            this.giftModal.robux = amount

            this.isShowGiftModal = true
            this.$store.dispatch('authorization', this.$cookies.get('access_token'))
          }
        })
        .catch(err => console.log(err))
    },
    redirectToHistory() {
      this.isShowGiftModal = false
      this.$router.push('/history')
    }
  },
  mounted() {
    if (this.$store.state.isScipGamepass) {
      this.showHint()
    }
  }
}
</script>
