<template>
  <div class="gift-list">
    <div class="gift-list__items">
      <GiftCard
        v-for="gift in giftsList"
        :key="gift.id"
        :gift="gift"
        :active="current.robux_amount === gift.robux_amount"
        @click="$emit('updateGift', gift)"
      />
    </div>
  </div>
</template>

<script>
import './giftList.scss'

import GiftCard from '@/components/Gift/GiftCard/GiftCard.vue'

import { mapGetters } from 'vuex'
export default {
  name: 'GiftList',
  props: {
    current: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    GiftCard
  },
  data() {
    return {
      giftsList: []
    }
  },
  created() {
    this.getGits()
  },
  computed: {
    ...mapGetters(['language']),
    isRussian() {
      return this.language === 'ru'
    }
  },
  methods: {
    getGits() {
      fetch('https://transfer.backend-pier.com/in_stock_gifts')
        .then(res => res.json())
        .then(data => {
          const { gifts } = data

          if (data.status) {
            this.giftsList = gifts.sort(
              (a, b) => a.robux_amount - b.robux_amount
            )
          }
        })
        .catch(err => console.log(err))
    }
  }
}
</script>
