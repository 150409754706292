<template>
  <div v-if="game" class="game-card" :class="{ active }">
    <img :src="game.image_uri" class="game-card__cover" alt="cover" />
    <div class="game-card__body">
      <div class="game-card__name">{{ game.name }}</div>
      <div class="game-card__info">
        <div class="game-card__rating">
          <Icon v-if="active" name="/base/like-blue.svg" size="sm" />
          <Icon v-else name="/base/like.svg" size="sm" />
          <span>{{ game.rate }}%</span>
          <span>Rating</span>
        </div>
        <div v-if="game.passes" class="game-card__number">
          {{ game.passes.length }} passes
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './gameCard.scss'

import Icon from '@/components/Base/Icon/Icon.vue'

export default {
  name: 'GameCard',
  components: { Icon },
  props: {
    game: {
      type: Object,
      default: () => {}
    },
    current: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    active() {
      return this.game._id === this.current._id
    }
  }
}
</script>
