<template>
  <div class="method-switch">
    <div
      v-for="(method, key) in methods"
      class="method-switch__item"
      :key="`method-${method.title}`"
      :class="{ active: key === current }"
      @click="onChange(key)"
    >
      <span>{{ method.title }}</span>
      <span v-if="method.badge" class="badge">
        <span class="mini">!</span>
      </span>
    </div>
  </div>
</template>

<script>
import './methodSwitch.scss'
import { isWithinWorkingHoursPass } from '@/utils/superPassWorkingHours'

export default {
  name: 'MethodSwitch',
  props: {
    methods: {
      type: Object,
      required: true,
      default: () => {}
    },
    current: {
      type: String
    }
  },
  methods: {
    isWithinWorkingHoursPass,
    onChange(key) {
      if (key === 'superPass') {
        const isTimeValid = this.isWithinWorkingHoursPass()

        if (!isTimeValid) {
          this.$emit('showTimeInfo')
          return
        }
      }
      this.$emit('update', key)
    }
  }
}
</script>
