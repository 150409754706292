module.exports.isWithinWorkingHoursPass = () => {
  const now = new Date()
  // Получаем текущее время в UTC
  const utcTime = now.getTime() + now.getTimezoneOffset() * 60000 // Преобразуем в миллисекунды

  // Учитываем смещение для Москвы (UTC+3)
  const moscowOffset = 3 * 60 * 60 * 1000 // 3 часа в миллисекундах

  const moscowTime = new Date(utcTime + moscowOffset)
  // Получаем текущий час в московском времени
  const moscowHours = moscowTime.getHours()

  return moscowHours >= 10 && moscowHours < 24
}
